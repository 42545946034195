import Footer from "../components/Footer";
import Header from "../components/Header";

const About = () => {
  return (
    <>
      <Header />;
      <section className="banner-section about-us">
        <div className="overlay">
          <div className="banner-content">
            <div className="container wow fadeInUp">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-10">
                  <div className="main-content text-center">
                    <div className="top-area section-text dark-sec">
                      <h5 className="sub-title">
                        Built for entrepreneurs, by entrepreneurs
                      </h5>
                      <h1 className="title">
                        The platform built to help businesses achieve their
                        ambitions faster
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-img-bottom pb-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="magnific-area d-flex align-items-center justify-content-around">
                  <div className="bg-area">
                    <img
                      className="bg-item"
                      src="assets/images/about-popup.png"
                      alt="banner"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="stars-info pt-120">
              <div className="row justify-content-center justify-content-around">
                <div className="col-sm-3">
                  <div className="single-box">
                    <div className="icon-box">
                      <img
                        src="assets/images/icon/stats-info-icon-1.png"
                        alt="icon"
                      />
                    </div>
                    <h5>2000+ employees</h5>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="single-box">
                    <div className="icon-box">
                      <img
                        src="assets/images/icon/stats-info-icon-2.png"
                        alt="icon"
                      />
                    </div>
                    <h5>3M+ users around the world</h5>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="single-box">
                    <div className="icon-box">
                      <img
                        src="assets/images/icon/stats-info-icon-3.png"
                        alt="icon"
                      />
                    </div>
                    <h5>€303.6 BN billion in processed volume in 2022</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-values">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-6">
                <div className="section-header text-center">
                  <h5 className="sub-title">Our values</h5>
                  <h2 className="title">
                    The values that drive everything we do
                  </h2>
                </div>
              </div>
            </div>
            <div className="row cus-mar align-items-center">
              <div className="col-lg-6">
                <div className="single-box d-flex">
                  <div className="img-box">
                    <img
                      src="assets/images/icon/values-icon-1.png"
                      alt="icon"
                    />
                  </div>
                  <div className="text-box">
                    <h4>Innovation</h4>
                    <p>
                      Through our commitment to innovation, we strive to
                      revolutionize the way our clients engage with financial
                      services, creating a seamless and personalized experience
                      that sets us apart from the competition.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-box d-flex">
                  <div className="img-box">
                    <img
                      src="assets/images/icon/values-icon-2.png"
                      alt="icon"
                    />
                  </div>
                  <div className="text-box">
                    <h4>Accountability</h4>
                    <p>
                      We firmly believe that being accountable is essential in
                      building trust, maintaining strong relationships with our
                      clients, and ensuring the integrity of our operations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-box d-flex">
                  <div className="img-box">
                    <img
                      src="assets/images/icon/values-icon-3.png"
                      alt="icon"
                    />
                  </div>
                  <div className="text-box">
                    <h4>Commitment</h4>
                    <p>
                      We are dedicated to providing our clients with exceptional
                      financial services and building long-term relationships
                      based on trust, integrity, and unwavering dedication.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single-box d-flex">
                  <div className="img-box">
                    <img
                      src="assets/images/icon/values-icon-4.png"
                      alt="icon"
                    />
                  </div>
                  <div className="text-box">
                    <h4>Team Work</h4>
                    <p>
                      We firmly believe that effective collaboration and synergy
                      among our employees are key drivers of success, enabling
                      us to deliver exceptional financial solutions and exceed
                      our clients' expectations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="location">
        <div className="overlay pb-120">
          <div className="container wow fadeInUp">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-8">
                <div className="section-header text-center">
                  <h5 className="sub-title">Our offices</h5>
                  <h2 className="title">
                    Come and visit our offices around the world
                  </h2>
                </div>
              </div>
            </div>
            <div className="row align-items-center cus-mar">
              <div className="col-xl-4 col-md-6">
                <div className="single-box">
                  <div className="head-area d-flex align-items-center">
                    <div className="img-box">
                      <img
                        src="assets/images/icon/location-icon-1.png"
                        alt="location"
                      />
                    </div>
                    <h4>San Francisco</h4>
                  </div>
                  <div className="email-phone">
                    <div className="img-box">
                      <img
                        src="assets/images/icon/email-icon-1.png"
                        alt="icon"
                      />
                    </div>
                    <p>
                      <a
                        href="mailto:info@payliofinance.com"
                        className="__cf_email__"
                      >
                        info@payliofinance.com
                      </a>
                    </p>
                  </div>
                  <div className="email-phone">
                    <div className="img-box">
                      <img
                        src="assets/images/icon/phone-icon-1.png"
                        alt="icon"
                      />
                    </div>
                    <p>415) 931 - 1616</p>
                  </div>
                  <div className="bottom-area">
                    <a href="#/">
                      View Location
                      <img
                        src="assets/images/icon/arrow-right-4.png"
                        alt="icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="single-box">
                  <div className="head-area d-flex align-items-center">
                    <div className="img-box">
                      <img
                        src="assets/images/icon/location-icon-2.png"
                        alt="location"
                      />
                    </div>
                    <h4>New York</h4>
                  </div>
                  <div className="email-phone">
                    <div className="img-box">
                      <img
                        src="assets/images/icon/email-icon-2.png"
                        alt="icon"
                      />
                    </div>
                    <p>
                      <a
                        href="mailto:info@payliofinance.com"
                        className="__cf_email__"
                      >
                        info@payliofinance.com
                      </a>
                    </p>
                  </div>
                  <div className="email-phone">
                    <div className="img-box">
                      <img
                        src="assets/images/icon/phone-icon-2.png"
                        alt="icon"
                      />
                    </div>
                    <p>415) 931 - 1616</p>
                  </div>
                  <div className="bottom-area">
                    <a href="#/">
                      View Location
                      <img
                        src="assets/images/icon/arrow-right-4.png"
                        alt="icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="single-box">
                  <div className="head-area d-flex align-items-center">
                    <div className="img-box">
                      <img
                        src="assets/images/icon/location-icon-3.png"
                        alt="location"
                      />
                    </div>
                    <h4>San Francisco</h4>
                  </div>
                  <div className="email-phone">
                    <div className="img-box">
                      <img
                        src="assets/images/icon/email-icon-3.png"
                        alt="icon"
                      />
                    </div>
                    <p>
                      <a
                        href="mailto:info@payliofinance.com"
                        className="__cf_email__"
                      >
                        info@payliofinance.com
                      </a>
                    </p>
                  </div>
                  <div className="email-phone">
                    <div className="img-box">
                      <img
                        src="assets/images/icon/phone-icon-3.png"
                        alt="icon"
                      />
                    </div>
                    <p>415) 931 - 1616</p>
                  </div>
                  <div className="bottom-area">
                    <a href="#/">
                      View Location
                      <img
                        src="assets/images/icon/arrow-right-4.png"
                        alt="icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
