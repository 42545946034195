import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <>
      <Header />
      <section className="banner-section inner-banner career">
        <div className="overlay">
          <div className="shape-area">
            <img
              src="assets/images/banner-blog.png"
              className="shape-1"
              alt="banner"
            />
          </div>
          <div className="banner-content">
            <div className="container wow fadeInUp">
              <div className="row align-items-center">
                <div className="col-lg-8 col-md-7">
                  <div className="main-content">
                    <h1>Blog</h1>
                    <div className="breadcrumb-area">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb d-flex align-items-center">
                          <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#/">Company</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Blog
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog-section">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-xl-6">
                <div className="section-header text-center">
                  <h2 className="title">Paylio Blog</h2>
                  <p>
                    Follow the Paylio blog for product announcements, feature
                    updates, user stories, and technical posts about banking.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                {/* <div className="top-area d-flex justify-content-between">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="cmn-btn active"
                        id="all-post-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#all-post"
                        type="button"
                        role="tab"
                        aria-controls="all-post"
                        aria-selected="true"
                      >
                        All
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="cmn-btn"
                        id="paylio-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#paylio"
                        type="button"
                        role="tab"
                        aria-controls="paylio"
                        aria-selected="false"
                      >
                        Paylio
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="cmn-btn"
                        id="business-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#business"
                        type="button"
                        role="tab"
                        aria-controls="business"
                        aria-selected="false"
                      >
                        Business
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="cmn-btn"
                        id="academy-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#academy"
                        type="button"
                        role="tab"
                        aria-controls="academy"
                        aria-selected="false"
                      >
                        Academy
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="cmn-btn"
                        id="how-to-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#how-to"
                        type="button"
                        role="tab"
                        aria-controls="how-to"
                        aria-selected="false"
                      >
                        How to
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="cmn-btn"
                        id="people-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#people"
                        type="button"
                        role="tab"
                        aria-controls="people"
                        aria-selected="false"
                      >
                        People
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="cmn-btn"
                        id="news-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#news"
                        type="button"
                        role="tab"
                        aria-controls="news"
                        aria-selected="false"
                      >
                        News
                      </button>
                    </li>
                  </ul>
                  <form action="#">
                    <div className="form-group mb-40 d-flex align-items-center">
                      <img
                        src="assets/images/icon/search-icon-2.png"
                        alt="icon"
                      />
                      <input type="text" placeholder="Search" />
                    </div>
                  </form>
                </div> */}
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="all-post"
                    role="tabpanel"
                    aria-labelledby="all-post-tab"
                  >
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-2.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - May 23, 2023</p>
                            <a href="#/">
                              <h5>
                                Factors That Help Shape Choice in the Online
                                Payment World
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-3.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - May 22, 2023</p>
                            <a href="#/">
                              <h5>
                                Various Online Payment Options and Tips to Avoid
                                Fraud..
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-4.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - May 7, 2023</p>
                            <a href="#/">
                              <h5>
                                Reasons Why You Should Go For Payment
                                solution...
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-5.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Mar 27, 2023</p>
                            <a href="#/">
                              <h5>
                                Top Reasons Why You Should Go For Payment
                                Management Solutions
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-6.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Jan 12, 2023</p>
                            <a href="#/">
                              <h5>
                                How to Make an International Money Transfer -
                                Send Money Safe And Fast
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-8.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Aug 10, 2022</p>
                            <a href="#/">
                              <h5>All you need to know about exchange rates</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-9.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Jun 14, 2022</p>
                            <a href="#/">
                              <h5>The best way to send money from the US...</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-10.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Apr 11, 2021</p>
                            <a href="#/">
                              <h5>5 Tips to Consider Before Investing in...</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-11.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 12, 2020</p>
                            <a href="#/">
                              <h4>
                                Paylio Celebrates 3 years - and 500K business
                                customers
                              </h4>
                            </a>
                            {/* <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-12.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Jan 7, 2020</p>
                            <a href="#/">
                              <h5>Track, split and settle bills together..</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-13.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Dec 25, 2019</p>
                            <a href="#/">
                              <h5>Share the love with Revolut Gifting</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="paylio"
                    role="tabpanel"
                    aria-labelledby="paylio-tab"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-5.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Top Reasons Why You Should Go For Payment
                                Management Solutions
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-6.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                How to Make an International Money Transfer -
                                Send Money Safe And Fast
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-1.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Money Transfer Services - Convenient and
                                Reliable{" "}
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-2.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Factors That Help Shape Choice in the Online
                                Payment World
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-3.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Various Online Payment Options and Tips to Avoid
                                Fraud..
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-4.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Reasons Why You Should Go For Payment
                                solution...
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-7.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Money Transfer Services - Convenient and
                                Reliable{" "}
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user-2.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-8.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>All you need to know about exchange rates</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-9.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>The best way to send money from the UK...</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-10.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>5 Tips to Consider Before Investing in...</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-11.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Paylio Celebrates 3 years - and 500K business
                                customers
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user-3.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-12.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>Track, split and settle bills together..</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-13.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>Share the love with Revolut Gifting</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="business"
                    role="tabpanel"
                    aria-labelledby="business-tab"
                  >
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-8.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>All you need to know about exchange rates</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-9.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>The best way to send money from the UK...</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-10.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>5 Tips to Consider Before Investing in...</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-1.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Money Transfer Services - Convenient and
                                Reliable{" "}
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-2.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Factors That Help Shape Choice in the Online
                                Payment World
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-3.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Various Online Payment Options and Tips to Avoid
                                Fraud..
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-4.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Reasons Why You Should Go For Payment
                                solution...
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-5.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Top Reasons Why You Should Go For Payment
                                Management Solutions
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-6.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                How to Make an International Money Transfer -
                                Send Money Safe And Fast
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-7.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Money Transfer Services - Convenient and
                                Reliable{" "}
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user-2.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-11.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Paylio Celebrates 3 years - and 500K business
                                customers
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user-3.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-12.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>Track, split and settle bills together..</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-13.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>Share the love with Revolut Gifting</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="academy"
                    role="tabpanel"
                    aria-labelledby="academy-tab"
                  >
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-2.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Factors That Help Shape Choice in the Online
                                Payment World
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-3.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Various Online Payment Options and Tips to Avoid
                                Fraud..
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-4.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Reasons Why You Should Go For Payment
                                solution...
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-1.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Money Transfer Services - Convenient and
                                Reliable{" "}
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-5.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Top Reasons Why You Should Go For Payment
                                Management Solutions
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-6.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                How to Make an International Money Transfer -
                                Send Money Safe And Fast
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-7.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Money Transfer Services - Convenient and
                                Reliable{" "}
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user-2.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-8.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>All you need to know about exchange rates</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-9.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>The best way to send money from the UK...</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-10.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>5 Tips to Consider Before Investing in...</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-11.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Paylio Celebrates 3 years - and 500K business
                                customers
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user-3.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-12.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>Track, split and settle bills together..</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-13.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>Share the love with Revolut Gifting</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="how-to"
                    role="tabpanel"
                    aria-labelledby="how-to-tab"
                  >
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-9.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>The best way to send money from the UK...</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-8.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>All you need to know about exchange rates</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-10.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>5 Tips to Consider Before Investing in...</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-1.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Money Transfer Services - Convenient and
                                Reliable{" "}
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-2.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Factors That Help Shape Choice in the Online
                                Payment World
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-3.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Various Online Payment Options and Tips to Avoid
                                Fraud..
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-4.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Reasons Why You Should Go For Payment
                                solution...
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-5.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Top Reasons Why You Should Go For Payment
                                Management Solutions
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-6.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                How to Make an International Money Transfer -
                                Send Money Safe And Fast
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-7.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Money Transfer Services - Convenient and
                                Reliable{" "}
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user-2.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-11.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Paylio Celebrates 3 years - and 500K business
                                customers
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user-3.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-12.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>Track, split and settle bills together..</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-13.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>Share the love with Revolut Gifting</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="people"
                    role="tabpanel"
                    aria-labelledby="people-tab"
                  >
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-3.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Various Online Payment Options and Tips to Avoid
                                Fraud..
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-2.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Factors That Help Shape Choice in the Online
                                Payment World
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-4.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Reasons Why You Should Go For Payment
                                solution...
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-1.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Money Transfer Services - Convenient and
                                Reliable{" "}
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-5.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Top Reasons Why You Should Go For Payment
                                Management Solutions
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-6.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                How to Make an International Money Transfer -
                                Send Money Safe And Fast
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-7.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Money Transfer Services - Convenient and
                                Reliable{" "}
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user-2.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-8.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>All you need to know about exchange rates</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-9.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>The best way to send money from the UK...</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-10.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>5 Tips to Consider Before Investing in...</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-11.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Paylio Celebrates 3 years - and 500K business
                                customers
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user-3.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-12.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>Track, split and settle bills together..</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-13.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>Share the love with Revolut Gifting</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="news"
                    role="tabpanel"
                    aria-labelledby="news-tab"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-7.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Money Transfer Services - Convenient and
                                Reliable{" "}
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user-2.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-3.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Various Online Payment Options and Tips to Avoid
                                Fraud..
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-2.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Factors That Help Shape Choice in the Online
                                Payment World
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-4.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Reasons Why You Should Go For Payment
                                solution...
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-1.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Money Transfer Services - Convenient and
                                Reliable{" "}
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-5.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                Top Reasons Why You Should Go For Payment
                                Management Solutions
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-6.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>
                                How to Make an International Money Transfer -
                                Send Money Safe And Fast
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-8.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>All you need to know about exchange rates</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-9.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>The best way to send money from the UK...</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-10.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>5 Tips to Consider Before Investing in...</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-post d-flex">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-11.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h4>
                                Paylio Celebrates 3 years - and 500K business
                                customers
                              </h4>
                            </a>
                            <p className="blog-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nam dolor lectus, posuere quis porttitor
                              nec, elementum eu sem. In eleifend mi ut est
                              tempor placerat. Nullam nulla ligula, tincidunt id
                              finibus sit amet, fringilla at arcu.
                            </p>
                            <div className="bottom-area d-flex align-items-center justify-content-between">
                              <img
                                src="assets/images/blog-user-3.png"
                                alt="blog"
                              />
                              <div className="btn-area d-flex align-items-center">
                                <a href="#/">
                                  Read More
                                  <img
                                    src="assets/images/icon/arrow-right-4.png"
                                    alt="icon"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-12.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>Track, split and settle bills together..</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10">
                        <div className="single-post">
                          <div className="thumbnail">
                            <img
                              src="assets/images/blog-image-13.png"
                              alt="blog"
                            />
                          </div>
                          <div className="blog-text">
                            <p className="date">By Paylio - Feb 7, 2022</p>
                            <a href="#/">
                              <h5>Share the love with Revolut Gifting</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Blog;
