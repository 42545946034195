import Header from "../components/Header";
import Footer from "../components/Footer";

const Budgeting = () => {
  return (
    <>
      <Header />
      <section className="banner-section corporate-card budgeting-analytics">
        <div className="overlay">
          <div className="banner-content">
            <div className="container wow fadeInUp">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-5 col-md-7">
                  <div className="main-content">
                    <div className="top-area section-text">
                      <h5 className="sub-title">Budgeting and Analytics</h5>
                      <h1 className="title">Never Overspend Again</h1>
                      <p className="xlr">
                        Take control of your money with smart budgeting and
                        analytics tools
                      </p>
                    </div>
                    <div className="bottom-area d-xxl-flex">
                      <a
                        href="https://dashboard.payliofinance.com/register"
                        className="cmn-btn"
                      >
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="earn-cashback features">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row pb-120 align-items-center justify-content-between">
              <div className="col-md-6 order-md-0 order-1">
                <div className="sec-image">
                  <img
                    src="assets/images/budgeting-analytics-features.png"
                    alt="budget"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-text">
                  <h5 className="sub-title">Get pinged to stay on track</h5>
                  <h2 className="title">
                    Set limits to help you stick to your budget
                  </h2>
                  <p>
                    We’ll calculate limits to recommend you based on your
                    spending predictions, or simply tell us how much you want to
                    spend
                  </p>
                </div>
                <div className="bottom-area d-xxl-flex">
                  <a
                    href="https://dashboard.payliofinance.com/register"
                    className="cmn-btn"
                  >
                    Try it out
                  </a>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="section-text">
                  <h5 className="sub-title">Budgeting and beyond</h5>
                  <h2 className="title">
                    Master Your Money With Smart Analytics
                  </h2>
                  <p>
                    Get a clear view of your spending with a breakdown by
                    merchants, categories, countries, and more
                  </p>
                </div>
                <div className="bottom-area d-xxl-flex">
                  <a
                    href="https://dashboard.payliofinance.com/register"
                    className="cmn-btn"
                  >
                    Check it Out
                  </a>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sec-image">
                  <img
                    src="assets/images/budgeting-analytics-features-2.png"
                    alt="budget"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Budgeting;
