import Header from "../components/Header";
import Footer from "../components/Footer";

const BusinessAccount = () => {
  return (
    <>
      <Header />
      <section className="banner-section business-account">
        <div className="overlay">
          <div className="banner-content">
            <div className="container wow fadeInUp">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-11 col-md-10">
                  <div className="main-content text-center">
                    <div className="top-area section-text">
                      <h5 className="sub-title">Easy, fast and transparent</h5>
                      <h1 className="title">
                        Digital Business Accounts Made for the Modern Business
                      </h1>
                      <ul>
                        <li>
                          <img
                            src="assets/images/icon/check-2.png"
                            alt="icon"
                          />
                          Save time
                        </li>
                        <li>
                          <img
                            src="assets/images/icon/check-2.png"
                            alt="icon"
                          />
                          Save money
                        </li>
                        <li>
                          <img
                            src="assets/images/icon/check-2.png"
                            alt="icon"
                          />
                          Helps you grow
                        </li>
                      </ul>
                    </div>
                    <div className="bottom-area d-xxl-flex justify-content-center">
                      <a
                        href="https://dashboard.payliofinance.com/register"
                        className="cmn-btn"
                      >
                        Open a Free Account
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-img-bottom pb-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="img-area">
                  <img
                    src="assets/images/business-dashboard-1.png"
                    alt="banner"
                  />
                  <img
                    className="right-img"
                    src="assets/images/business-dashboard-2.png"
                    alt="banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="business-account">
        <div className="overlay pb-120">
          <div className="container wow fadeInUp">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="section-area">
                  <h2 className="title">
                    Send and receive money from any bank account with free local
                    transfers
                  </h2>
                  <p>
                    Get your own unique business account number which you can
                    share with clients to receive money.
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <div className="sec-image">
                  <img
                    src="assets/images/business-features-1.png"
                    alt="business"
                    className="max-un"
                  />
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between pb-120 pt-120">
              <div className="row align-items-center justify-content-between">
                <div className="col-md-5 order-md-0 order-1">
                  <div className="sec-image d-rtl">
                    <img
                      src="assets/images/business-features-2.png"
                      alt="business"
                      className="max-un"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="section-area">
                    <h2 className="title">
                      Spend less on FX fees, more on growth
                    </h2>
                    <p>
                      Send and receive international payments in 50+ currencies
                      with low, transparent fees. Multi-currency accounts coming
                      soon.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="section-area">
                  <h2 className="title">Move faster when you add your team</h2>
                  <p>
                    Onboard your team in minutes, with the right level of secure
                    access. Issue cards to help with budgeting, or use our
                    transaction approval flows to keep track of trends and speed
                    up your team.
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <div className="sec-image">
                  <img
                    src="assets/images/business-features-3.png"
                    alt="business"
                    className="max-un"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="call-action earn-when-spend"
        style={{ marginBottom: "50px" }}
      >
        <div className="overlay pt-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 text-center">
                <div className="section-text">
                  <h2 className="title">Earn When You Spend</h2>
                  <p>
                    Get a corporate card that gives you cash back on digital
                    spend. Issue unlimited cards to your team to manage
                    corporate expenses.
                  </p>
                </div>
                <div className="btn-area">
                  <a
                    href="https://dashboard.payliofinance.com/register"
                    className="cmn-btn"
                  >
                    Open a Free Account
                  </a>
                </div>
                <ul className="mt-60">
                  <li>
                    <img src="assets/images/icon/aws.png" alt="icon" />
                  </li>
                  <li>
                    <img src="assets/images/icon/shopify.png" alt="icon" />
                  </li>
                  <li>
                    <img src="assets/images/icon/magento.png" alt="icon" />
                  </li>
                  <li>
                    <img src="assets/images/icon/xero.png" alt="icon" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default BusinessAccount;
