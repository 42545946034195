import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import Blog from "./pages/Blog";
import Budgeting from "./pages/Budgeting";
import BusinessAccount from "./pages/BusinessAccount";
import Corporate from "./pages/Corporate";
import Expense from "./pages/Expense";
import Help from "./pages/Help";
import Integrations from "./pages/Integrations";
import Invoice from "./pages/Invoice";
import Payments from "./pages/Payments";
import Security from "./pages/Security";
import Subscriptions from "./pages/Subscriptions";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/budgeting" element={<Budgeting />} />
      <Route path="/business-account" element={<BusinessAccount />} />
      <Route path="/corporate-card" element={<Corporate />} />
      <Route path="/expense-management" element={<Expense />} />
      <Route path="/help-center" element={<Help />} />
      <Route path="/integrations" element={<Integrations />} />
      <Route path="/invoice-management" element={<Invoice />} />
      <Route path="/payments" element={<Payments />} />
      <Route path="/security" element={<Security />} />
      <Route path="/subscriptions" element={<Subscriptions />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
