import Header from "../components/Header";
import Footer from "../components/Footer";

const Expense = () => {
  return (
    <>
      <Header />
      <section className="banner-section corporate-card expense">
        <div className="overlay">
          <div className="banner-content">
            <div className="container wow fadeInUp">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6 col-md-7">
                  <div className="main-content">
                    <div className="top-area section-text">
                      <h5 className="sub-title">
                        Global payment solution for businesses
                      </h5>
                      <h1 className="title">
                        Control Company Expenses at Every Step
                      </h1>
                      <p className="xlr">
                        Empower your team while keeping the company efficient
                        and informed, every step of the way.
                      </p>
                    </div>
                    <div className="bottom-area d-xxl-flex">
                      <a
                        href="https://dashboard.payliofinance.com/register"
                        className="cmn-btn"
                      >
                        Open a Free Account
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="global-payment feature">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="section-header text-center">
                  <h5 className="sub-title">Access your funds even faster</h5>
                  <h2 className="title">
                    More Savings, More Time, More Peace of Mind
                  </h2>
                  <p>
                    Get everything you need to create and manage your card
                    program with our unified payments platform.
                  </p>
                </div>
              </div>
            </div>
            <div className="row cus-mar">
              <div className="col-lg-4 col-md-6">
                <div className="single-item">
                  <img
                    src="assets/images/icon/features-corporate-icon-1.png"
                    alt="icon"
                  />
                  <h5>Earn Cashback</h5>
                  <p>
                    The banks don't reward you like this. Earn 1% cashback..
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-item">
                  <img
                    src="assets/images/icon/features-corporate-icon-2.png"
                    alt="icon"
                  />
                  <h5>Track Spending</h5>
                  <p>
                    Get real-time notifications at your fingertips. Set budgets
                    to avoid..
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-item">
                  <img
                    src="assets/images/icon/features-corporate-icon-3.png"
                    alt="icon"
                  />
                  <h5>Completely Free</h5>
                  <p>
                    No sign up, annual, or hidden fees. Get started in 5 minutes
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="earn-cashback team-instantly">
        <div className="overlay pb-120">
          <div className="container wow fadeInUp">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-5 order-md-0 order-1">
                <div className="sec-image d-rtl">
                  <img
                    src="assets/images/team-instantly.png"
                    alt="earn"
                    className="max-un"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-area">
                  <h5 className="sub-title">Card spending you control</h5>
                  <h2 className="title">Issue Cards to Your Team Instantly</h2>
                  <p>
                    Unlimited virtual cards that you can issue to each employee,
                    merchant, or use-case. Say goodbye to sharing cards or
                    one-time passwords, and hello to faster purchasing. All for
                    free.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stay-control">
        <div className="overlay pb-120">
          <div className="container wow fadeInUp">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-5">
                <div className="section-text">
                  <h5 className="sub-title">Stay in Control, in Real Time</h5>
                  <h2 className="title">
                    Stay in control over your cards and transactions
                  </h2>
                  <p>
                    Set card budgets to control spend, update spend limits
                    anytime. Get real-time spend notifications and save time
                    with auto categorisation.
                  </p>
                </div>
                <ul>
                  <li>
                    <img
                      src="assets/images/icon/stay-control-icon-1.png"
                      alt="earn"
                    />
                    <p>Freeze Card</p>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon/stay-control-icon-2.png"
                      alt="earn"
                    />
                    <p>Set Spend Limit</p>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon/stay-control-icon-3.png"
                      alt="earn"
                    />
                    <p>Replace Card</p>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon/stay-control-icon-4.png"
                      alt="earn"
                    />
                    <p>Cancel Card</p>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <div className="sec-image">
                  <img
                    src="assets/images/stay-control-image.png"
                    alt="earn"
                    className="max-un"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="earn-cashback costly-surprises">
        <div className="overlay pb-120">
          <div className="container wow fadeInUp">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-5 order-md-0 order-1">
                <div className="sec-image d-rtl">
                  <img
                    src="assets/images/costly-surprises.png"
                    alt="earn"
                    className="max-un"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-area">
                  <h5 className="sub-title">Avoid Costly Surprises</h5>
                  <h2 className="title">
                    Say Goodbye to Manual Paper Expensing
                  </h2>
                  <p>
                    Have your team upload receipts on Paylio for instant
                    reconciliation. Close your books 5x faster with our direct
                    accounting integrations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="call-action" style={{ marginBottom: "50px" }}>
        <div className="overlay pt-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 text-center">
                <div className="section-text">
                  <h5 className="sub-title">What are you waiting for?</h5>
                  <h2 className="title">Get a Card You Control Today</h2>
                  <p>
                    No-fuss company cards for team members, instant virtual
                    cards for security and flexibility — all set up with our
                    easy expense management
                  </p>
                </div>
                <div className="btn-area">
                  <a
                    href="https://dashboard.payliofinance.com/register"
                    className="cmn-btn"
                  >
                    Sign up in minutes
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Expense;
