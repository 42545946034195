import Footer from "../components/Footer";
import Header from "../components/Header";

const Help = () => {
  return (
    <>
      <Header />
      <section className="banner-section help-center">
        <div className="overlay">
          <div className="banner-content">
            <div className="container wow fadeInUp">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-8 col-md-7">
                  <div className="main-content text-center">
                    <div className="top-area section-text dark-sec">
                      <h3 className="title">How can we help you today?</h3>
                    </div>
                    <form action="#">
                      <div className="form-group mb-40 d-flex align-items-center">
                        <img
                          src="assets/images/icon/search-icon.png"
                          alt="icon"
                        />
                        <input
                          type="text"
                          placeholder="Search Knowledge base"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="help-center-section">
        <div className="overlay pb-120">
          <div className="container wow fadeInUp">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-12">
                <div className="nav-tabs-area">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="cmn-btn active"
                        id="personal-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#personal"
                        type="button"
                        role="tab"
                        aria-controls="personal"
                        aria-selected="true"
                      >
                        personal
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="cmn-btn"
                        id="business-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#business"
                        type="button"
                        role="tab"
                        aria-controls="business"
                        aria-selected="false"
                      >
                        business
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="tab-content mt-40">
                  <div
                    className="tab-pane fade show active"
                    id="personal"
                    role="tabpanel"
                    aria-labelledby="personal-tab"
                  >
                    <div className="row justify-content-sm-start justify-content-center">
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/personal-icon-1.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>About Paylio</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/personal-icon-2.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Wallet Creation</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/personal-icon-3.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Adding Money</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/personal-icon-4.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Paylio Cards</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/personal-icon-5.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Sending Money</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/personal-icon-6.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Account Mangament</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/personal-icon-7.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Adding Recipient</h5>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="business"
                    role="tabpanel"
                    aria-labelledby="business-tab"
                  >
                    <div className="row justify-content-center">
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/personal-icon-1.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>About Paylio</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/business-icon-1.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Accepting Payments</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/business-icon-2.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Adding Money</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/business-icon-3.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Transactions</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/business-icon-4.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Deposits</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/personal-icon-2.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Wallet</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/personal-icon-1.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Refund</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/personal-icon-4.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Paylio Cards</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/business-icon-5.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Designated Bank Acc</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/business-icon-6.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Payment Gateway</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/business-icon-7.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>3D Secure Auth.</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-sm-6 col-9">
                        <div className="single-box">
                          <div className="img-area">
                            <img
                              src="assets/images/icon/business-icon-8.png"
                              alt="icon"
                            />
                          </div>
                          <a href="#/">
                            <h5>Fees and Allowance</h5>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="call-action" style={{ marginBottom: "100px" }}>
        <div className="overlay pt-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 text-center">
                <div className="section-text">
                  <h5 className="sub-title">Let us help you!</h5>
                  <h2 className="title">
                    Didn’t find what you were looking for?
                  </h2>
                </div>
                <div className="btn-area">
                  <a href="#/" className="cmn-btn">
                    Submit a request
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Help;
