import Header from "../components/Header";
import Footer from "../components/Footer";

const Integrations = () => {
  return (
    <>
      <Header />
      <section className="banner-section business-account integrations">
        <div className="overlay">
          <div className="banner-content">
            <div className="container wow fadeInUp">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-10">
                  <div className="main-content text-center">
                    <div className="top-area section-text dark-sec">
                      <h5 className="sub-title">Budgeting and Analytics</h5>
                      <h1 className="title">
                        Automate Your Workflows With Direct Integrations
                      </h1>
                      <p>Close the books faster, without errors.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-img-bottom pb-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="img-area">
                  <img
                    src="assets/images/integrations-banner.png"
                    alt="banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="earn-cashback features">
        <div className="overlay pb-120">
          <div className="container wow fadeInUp">
            <div className="row pb-120 align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="section-area">
                  <h5 className="sub-title">Close the books in record time</h5>
                  <h2 className="title">Sync With Your Favourite Software</h2>
                  <p>
                    Automate book-keeping and reconciliation, and avoid manual
                    errors with direct integrations to your accounting software.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sec-image">
                  <img
                    src="assets/images/integrations-features-1.png"
                    alt="banner"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
            <div className="row pb-120 align-items-center justify-content-between">
              <div className="col-md-6 order-md-0 order-1">
                <div className="sec-image">
                  <img
                    src="assets/images/integrations-features-2.png"
                    alt="banner"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-area">
                  <h2 className="title">
                    Intelligent categorisation that halves your work
                  </h2>
                  <p>
                    Save hours each month with intelligent categorisation and
                    automated general ledger mapping to your accounting
                    software.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="section-text">
                  <h2 className="title">
                    Integrated invoicing and reconcilation
                  </h2>
                  <p>
                    Send invoices directly from your Paylio account. When a
                    payment is received, the invoice will be automatically
                    marked as paid and reconciled.
                  </p>
                </div>
                <div className="btn-area">
                  <a
                    href="https://dashboard.payliofinance.com/register"
                    className="cmn-btn"
                  >
                    Try it out
                  </a>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sec-image ex-mar">
                  <img
                    src="assets/images/integrations-features-3.png"
                    alt="banner"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="call-action integration">
        <div className="overlay pt-120 pb-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 text-center">
                <div className="section-text">
                  <h5 className="sub-title">Platform integration</h5>
                  <h2 className="title">
                    Integrate Plugins to Start Accepting Payments in Minutes
                  </h2>
                  <p>
                    We make integrating with the most popular e-commerce
                    platforms seamless. Customise, or enjoy out-of-the-box
                    features.
                  </p>
                </div>
                <div className="btn-area">
                  <a
                    href="https://dashboard.payliofinance.com/register"
                    className="cmn-btn"
                  >
                    Open a Free Account
                  </a>
                </div>
                <ul className="mt-60">
                  <li>
                    <img src="assets/images/icon/aws.png" alt="icon" />
                  </li>
                  <li>
                    <img src="assets/images/icon/shopify.png" alt="icon" />
                  </li>
                  <li>
                    <img src="assets/images/icon/magento.png" alt="icon" />
                  </li>
                  <li>
                    <img src="assets/images/icon/xero.png" alt="icon" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Integrations;
