import Header from "../components/Header";
import Footer from "../components/Footer";

const Corporate = () => {
  return (
    <>
      <Header />
      <section className="banner-section corporate-card">
        <div className="overlay">
          <div className="banner-content">
            <div className="container wow fadeInUp">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6 col-md-7">
                  <div className="main-content">
                    <div className="top-area section-text">
                      <h5 className="sub-title">
                        Global payment solution for businesses
                      </h5>
                      <h1 className="title">The Corporate Card for Startups</h1>
                      <p className="xlr">
                        The card that saves you time and your business money.
                        Track spend in real-time and earn cashback on digital
                        spend.
                      </p>
                    </div>
                    <div className="bottom-area d-xxl-flex">
                      <a
                        href="https://dashboard.payliofinance.com/register"
                        className="cmn-btn"
                      >
                        Open a Free Account
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="global-payment feature">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="section-header text-center">
                  <h5 className="sub-title">Access your funds even faster</h5>
                  <h2 className="title">
                    More Savings, More Time, More Peace of Mind
                  </h2>
                  <p>
                    Get everything you need to create and manage your card
                    program with our unified payments platform.
                  </p>
                </div>
              </div>
            </div>
            <div className="row cus-mar">
              <div className="col-lg-4 col-md-6">
                <div className="single-item">
                  <img
                    src="assets/images/icon/features-corporate-icon-1.png"
                    alt="icon"
                  />
                  <h5>Earn Cashback</h5>
                  <p>
                    The banks don't reward you like this. Earn 1% cashback..
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-item">
                  <img
                    src="assets/images/icon/features-corporate-icon-2.png"
                    alt="icon"
                  />
                  <h5>Track Spending</h5>
                  <p>
                    Get real-time notifications at your fingertips. Set budgets
                    to avoid..
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-item">
                  <img
                    src="assets/images/icon/features-corporate-icon-3.png"
                    alt="icon"
                  />
                  <h5>Completely Free</h5>
                  <p>
                    No sign up, annual or hidden fees. Get started in 5 minutes
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="multi-currency">
        <div className="overlay pb-120">
          <div className="container wow fadeInUp">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="section-text">
                  <h5 className="sub-title">Multi-currency Company Cards</h5>
                  <h2 className="title">
                    Save with 5x cheaper international payments
                  </h2>
                  <p>
                    Spend at the visa rate, with a low transparent fee. Easy,
                    fast, transparent. Paylio gives over 10,000 business owners
                    their money and time back.
                  </p>
                </div>
                <div className="btn-area">
                  <a
                    href="https://dashboard.payliofinance.com/register"
                    className="cmn-btn"
                  >
                    Open Account
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="sec-image">
                  <img
                    src="assets/images/multi-currency-img.png"
                    alt="earn"
                    className="max-un"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="earn-cashback">
        <div className="overlay pb-120">
          <div className="container wow fadeInUp">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-5 order-md-0 order-1">
                <div className="sec-image d-rtl">
                  <img
                    src="assets/images/earn-cashback-image.png"
                    alt="earn"
                    className="max-un"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-text">
                  <h5 className="sub-title">Radically better company cards</h5>
                  <h2 className="title">Earn cashback on your digital spend</h2>
                  <p>
                    Earn 1% cashback on digital marketing and SaaS spend with
                    the biggest names in business tech.
                  </p>
                </div>
                <div className="btn-area">
                  <a
                    href="https://dashboard.payliofinance.com/register"
                    className="cmn-btn"
                  >
                    Open Account
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stay-control">
        <div className="overlay pb-120">
          <div className="container wow fadeInUp">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-5">
                <div className="section-text">
                  <h5 className="sub-title">Stay in Control, in Real Time</h5>
                  <h2 className="title">
                    Stay in control over your cards and transactions
                  </h2>
                  <p>
                    Set card budgets to control spend, update spend limits
                    anytime. Get real-time spend notifications and save time
                    with auto categorisation.
                  </p>
                </div>
                <ul>
                  <li>
                    <img
                      src="assets/images/icon/stay-control-icon-1.png"
                      alt="control"
                    />
                    <p>Freeze Card</p>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon/stay-control-icon-2.png"
                      alt="control"
                    />
                    <p>Set Spend Limit</p>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon/stay-control-icon-3.png"
                      alt="control"
                    />
                    <p>Replace Card</p>
                  </li>
                  <li>
                    <img
                      src="assets/images/icon/stay-control-icon-4.png"
                      alt="control"
                    />
                    <p>Cancel Card</p>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <div className="sec-image">
                  <img
                    src="assets/images/stay-control-image.png"
                    alt="control"
                    className="max-un"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="secure-integrated">
        <div className="overlay pb-120">
          <div className="container wow fadeInUp">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-5 order-md-0 order-1">
                <div className="sec-image d-rtl">
                  <img
                    src="assets/images/secure-integrated-illu.png"
                    alt="secure"
                    className="max-un"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-text">
                  <h5 className="sub-title">Secure and integrated</h5>
                  <h2 className="title">
                    Control the security of your company cards
                  </h2>
                  <p>
                    Cancel or freeze cards anytime, with advanced fraud
                    protection. All your transactions are automatically synced
                    with your accounting software.
                  </p>
                </div>
                <div className="section-bottom">
                  <ul>
                    <li>
                      <img src="assets/images/icon/check-3.png" alt="icon" />
                      Temporarily freeze missing cards in your web dashboard
                    </li>
                    <li>
                      <img src="assets/images/icon/check-3.png" alt="icon" />
                      Enable location-based security to reduce fraudulent
                      transactions
                    </li>
                    <li>
                      <img src="assets/images/icon/check-3.png" alt="icon" />
                      Disable contactless, online or swipe payments when
                      necessary to ensure your card’s safety
                    </li>
                    <li>
                      <img src="assets/images/icon/check-3.png" alt="icon" />
                      Toggle the ability to make cash withdrawals from ATMs for
                      extra security
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="call-action" style={{ marginBottom: "100px" }}>
        <div className="overlay pt-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 text-center">
                <div className="section-text">
                  <h2 className="title">Join Paylio Business.</h2>
                  <p>
                    No-fuss company cards for team members, instant virtual
                    cards for security and flexibility — all set up with our
                    easy expense management
                  </p>
                </div>
                <div className="btn-area">
                  <a
                    href="https://dashboard.payliofinance.com/register"
                    className="cmn-btn"
                  >
                    Sign up in minutes
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Corporate;
