import Header from "../components/Header";
import Footer from "../components/Footer";

const Invoice = () => {
  return (
    <>
      <Header />
      <section className="banner-section invoice-management">
        <div className="overlay">
          <div className="shape-area">
            <img
              src="assets/images/icon/invoice-icon-1.png"
              className="shape-1"
              alt="icon"
            />
            <img
              src="assets/images/icon/invoice-icon-2.png"
              className="shape-2"
              alt="icon"
            />
            <img
              src="assets/images/icon/invoice-icon-3.png"
              className="shape-3"
              alt="icon"
            />
          </div>
          <div className="banner-content">
            <div className="container wow fadeInUp">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-9">
                  <div className="main-content text-center">
                    <div className="top-area section-text dark-sec">
                      <h5 className="sub-title">Smarter Invoicing</h5>
                      <h1 className="title">Invoices Made Effortless</h1>
                      <p>
                        Create, send &amp; manage invoices effortlessly. Unleash
                        the power of an integrated finance stack with Invoicing
                        and the Paylio business account.
                      </p>
                    </div>
                    <div className="btn-area">
                      <a
                        href="https://dashboard.payliofinance.com/register"
                        className="cmn-btn"
                      >
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="earn-cashback features">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row pb-120 align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="section-area">
                  <h5 className="sub-title">Faster invoice payments</h5>
                  <h2 className="title">
                    The easiest, fastest way to create invoices
                  </h2>
                  <p>
                    Create professional looking, tax compliant invoices that
                    match your brand. Your payment details are automatically
                    included, so it’s easier for customers to pay you.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sec-image">
                  <img
                    src="assets/images/invoice-features-1.png"
                    alt="banner"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
            <div className="row pb-120 align-items-center justify-content-between">
              <div className="col-md-6 order-md-0 order-1">
                <div className="sec-image">
                  <img
                    src="assets/images/invoice-features-2.png"
                    alt="banner"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-area">
                  <h5 className="sub-title">Business made simple</h5>
                  <h2 className="title">
                    Know when you’re likely to get paid. So you can plan ahead
                  </h2>
                  <p>
                    Get a bird’s eye view of paid and unpaid invoices. Know when
                    you’re likely to be paid, so you can plan accordingly. Be in
                    the know &amp; receive alerts as soon as you get paid.
                  </p>
                </div>
              </div>
            </div>
            <div className="row pb-120 align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="section-area">
                  <h5 className="sub-title">Set up automatic reminders</h5>
                  <h2 className="title">
                    Set auto payment reminders for your customers
                  </h2>
                  <p>
                    Remind customers of an upcoming or overdue payment, without
                    being intrusive or awkward. Reduce payment delays, without
                    following up all the time.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sec-image">
                  <img
                    src="assets/images/invoice-features-3.png"
                    alt="banner"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
            <div className="row pb-120 align-items-center justify-content-between">
              <div className="col-md-6 order-md-0 order-1">
                <div className="sec-image">
                  <img
                    src="assets/images/invoice-features-4.png"
                    alt="banner"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-area">
                  <h5 className="sub-title">Run Your Business Your Way</h5>
                  <h2 className="title">
                    Sit back and relax with automatic reconcillation
                  </h2>
                  <p>
                    Matching incoming transfers are automatically synced and
                    marked as paid. Experience the power of an integrated
                    finance stack.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="section-area">
                  <h5 className="sub-title">
                    Professional invoices now just a few clicks away
                  </h5>
                  <h2 className="title">
                    Spend less time doing boring administrative tasks
                  </h2>
                  <p>
                    Unleash the power of Paylio’s business account along with
                    your invoices. and manage it all with a bird’s eye view.
                    Save a tonne of time, effortlessly.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sec-image ex-mar">
                  <img
                    src="assets/images/invoice-features-5.png"
                    alt="banner"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Invoice;
