import Header from "../components/Header";
import Footer from "../components/Footer";

const Subscriptions = () => {
  return (
    <>
      <Header />
      <section className="banner-section corporate-card subscriptions">
        <div className="overlay">
          <div className="banner-content">
            <div className="container wow fadeInUp">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6 col-md-7">
                  <div className="main-content">
                    <div className="top-area section-text">
                      <h5 className="sub-title">Scheduled Payments</h5>
                      <h1 className="title">Manage Subscriptions Smarter</h1>
                      <p className="xlr">
                        One place for all your recurring payments
                      </p>
                    </div>
                    <div className="bottom-area d-xxl-flex">
                      <a
                        href="https://dashboard.payliofinance.com/register"
                        className="cmn-btn"
                      >
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="earn-cashback features">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row pb-120 align-items-center justify-content-between">
              <div className="col-md-6 order-md-0 order-1">
                <div className="sec-image">
                  <img
                    src="assets/images/subscriptions-features.png"
                    alt="sub"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-text">
                  <h5 className="sub-title">
                    Block unwanted subscriptions easily
                  </h5>
                  <h2 className="title">Organise subscriptions in one place</h2>
                  <p>
                    Add your scheduled payments to Paylio to track your spending
                    and see where you could save
                  </p>
                </div>
                <div className="bottom-area d-xxl-flex">
                  <a
                    href="https://dashboard.payliofinance.com/register"
                    className="cmn-btn"
                  >
                    Try it out
                  </a>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="section-text">
                  <h5 className="sub-title">
                    Subscribe to a better financial life
                  </h5>
                  <h2 className="title">Get reminders for upcoming payments</h2>
                  <p>
                    Say goodbye to surprise charges with notifications if
                    there's not enough money to cover scheduled payments
                  </p>
                </div>
                <div className="bottom-area d-xxl-flex">
                  <a
                    href="https://dashboard.payliofinance.com/register"
                    className="cmn-btn"
                  >
                    Try it out
                  </a>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sec-image exb-mar">
                  <img
                    src="assets/images/subscriptions-features-2.png"
                    alt="sub"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Subscriptions;
