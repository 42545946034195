import Header from "../components/Header";
import Footer from "../components/Footer";
// import Preloader from "../components/Preloader";

const Home = () => {
  return (
    <>
      {/* <Preloader /> */}
      <a href="#/" className="scrollToTop">
        <i className="fas fa-angle-double-up"></i>
      </a>
      <Header />
      <section className="banner-section index">
        <div className="overlay">
          <div className="shape-area">
            <img
              src="/assets/images/banner-box.png"
              className="obj-1"
              alt="banner"
            />
            <img
              src="/assets/images/banner-human.png"
              className="obj-2"
              alt="banner"
            />
            <img
              src="/assets/images/banner-rocket.png"
              className="obj-3"
              alt="banner"
            />
            <img
              src="/assets/images/banner-clock.png"
              className="obj-4"
              alt="banner"
            />
          </div>
          <div className="banner-content">
            <div className="container wow fadeInUp">
              <div className="content-shape">
                <img
                  src="/assets/images/banner-wallet.png"
                  className="obj-1"
                  alt="banner"
                />
              </div>
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-5 col-md-6">
                  <div className="main-content">
                    <div className="top-area section-text">
                      <h5 className="sub-title">
                        Trusted by over 3M customers
                      </h5>
                      <h1 className="title">Pay Anyone, Anywhere</h1>
                      <p className="xlr">
                        Quickly and easily send, receive and request money
                        online with Paylio. Get a customised solution to fit
                        your business needs.
                      </p>
                    </div>
                    <div className="bottom-area d-xxl-flex">
                      <a
                        href="https://dashboard.payliofinance.com/register"
                        className="cmn-btn"
                      >
                        Open a Free Account
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="counter-section">
          <div className="container wow fadeInUp">
            <div className="row cus-mar">
              <div className="col-xl-3 col-md-3 col-sm-6">
                <div className="single-area d-flex align-items-center justify-content-center">
                  <div className="text-area text-center">
                    <h2>
                      <span className="counter">50</span>
                      <span>+</span>
                    </h2>
                    <p>Supported Currencies</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-6">
                <div className="single-area d-flex align-items-center justify-content-center">
                  <div className="text-area text-center">
                    <h2>
                      <span className="counter">100</span>
                      <span>+</span>
                    </h2>
                    <p>Available Countries</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-6">
                <div className="single-area d-flex align-items-center justify-content-center">
                  <div className="text-area text-center">
                    <h2>
                      <span className="counter">70</span>
                      <span>+</span>
                    </h2>
                    <p>Payment Methods</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-6">
                <div className="single-area d-flex align-items-center justify-content-center">
                  <div className="text-area text-center">
                    <h2>
                      <span className="counter">24</span>
                      <span>/</span>
                      <span className="counter">7</span>
                    </h2>
                    <p>Support Team</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="global-payment">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-header text-center">
                  <h5 className="sub-title">Send money in a heartbeat</h5>
                  <h2 className="title">The World At Your Fingertips</h2>
                  <p>
                    Sign up to start saving on international money transfers and
                    currency exchange.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-6 col-xl-5 order-xl-0 order-1">
                <div className="image-area d-rtl left-side">
                  <img
                    src="assets/images/global-payment-img.png"
                    alt="images"
                    className="max-un"
                  />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-7">
                <div className="row cus-mar">
                  <div className="col-sm-6 col-6">
                    <div className="single-item">
                      <img
                        src="assets/images/icon/global-payment-icon-1.png"
                        alt="icon"
                      />
                      <h5>Peace of Mind</h5>
                      <p>
                        We are dedicated to providing you with comprehensive
                        financial solutions designed to safeguard your present
                        and future.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-6">
                    <div className="single-item">
                      <img
                        src="assets/images/icon/global-payment-icon-2.png"
                        alt="icon"
                      />
                      <h5>Business-Ready</h5>
                      <p>
                        We have developed a cutting-edge platform specifically
                        designed to make your business journey smoother and more
                        successful.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-6">
                    <div className="single-item">
                      <img
                        src="assets/images/icon/global-payment-icon-3.png"
                        alt="icon"
                      />
                      <h5>100% Transparent</h5>
                      <p>
                        We are 100% transparent, empowering you with clear
                        insights and complete visibility into your financial
                        matters.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-6">
                    <div className="single-item">
                      <img
                        src="assets/images/icon/global-payment-icon-4.png"
                        alt="icon"
                      />
                      <h5>International Network</h5>
                      <p>
                        Our platform is designed to connect you with a vast
                        network of financial services and opportunities across
                        the globe.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-solutions">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-header dark-sec text-center">
                  <h5 className="sub-title">
                    High speeds. Low fees. No hassle.
                  </h5>
                  <h2 className="title">All Your Payments In One Place</h2>
                  <p>
                    Get used to low fees and great exchange rates on
                    international money transfers.Expand your business worldwide
                  </p>
                </div>
              </div>
            </div>
            <div className="row cus-mar">
              <div className="col-xl-3 col-6">
                <div className="single-item">
                  <img
                    src="/assets/images/icon/our-solutions-icon-1.png"
                    alt="icon"
                  />
                  <h5>Payments</h5>
                </div>
              </div>
              <div className="col-xl-3 col-6">
                <div className="single-item">
                  <img
                    src="/assets/images/icon/our-solutions-icon-2.png"
                    alt="icon"
                  />
                  <h5>Collections</h5>
                </div>
              </div>
              <div className="col-xl-3 col-6">
                <div className="single-item">
                  <img
                    src="/assets/images/icon/our-solutions-icon-3.png"
                    alt="icon"
                  />
                  <h5>Conversions</h5>
                </div>
              </div>
              <div className="col-xl-3 col-6">
                <div className="single-item">
                  <img
                    src="/assets/images/icon/our-solutions-icon-4.png"
                    alt="icon"
                  />
                  <h5>Global Account</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="how-it-works">
        <div className="overlay pb-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-header text-center">
                  <h5 className="sub-title">How it works?</h5>
                  <h2 className="title">Just few steps to start</h2>
                  <p>It's easier than you think. Follow 3 simple easy steps</p>
                </div>
              </div>
            </div>
            <div className="row cus-mar">
              <div className="col-xl-3 col-sm-6 col-6">
                <div className="single-item first text-center">
                  <img
                    src="/assets/images/icon/how-works-icon-1.png"
                    alt="icon"
                  />
                  <h5>Register for free</h5>
                  <p>Simply sign up online for free and verify your identity</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-6">
                <div className="single-item second text-center">
                  <img
                    src="/assets/images/icon/how-works-icon-2.png"
                    alt="icon"
                  />
                  <h5>Set up your transfer</h5>
                  <p>Add a recipient's details and choose which currency</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-6">
                <div className="single-item first text-center">
                  <img
                    src="/assets/images/icon/how-works-icon-3.png"
                    alt="icon"
                  />
                  <h5>Make your payment</h5>
                  <p>Send funds with a bank transfer</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-6">
                <div className="single-item text-center">
                  <img
                    src="/assets/images/icon/how-works-icon-4.png"
                    alt="icon"
                  />
                  <h5>You're all done!</h5>
                  <p>We inform you when the money has been sent</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs-section">
        <div className="overlay pt-120 pb-120">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-7">
                <div className="section-header text-center">
                  <h5 className="sub-title">Frequently Asked Questions</h5>
                  <h2 className="title">
                    If you've got questions we have answers
                  </h2>
                  <p>We have a list of frequently asked questions about us</p>
                </div>
              </div>
            </div>
            <div className="row cus-mar">
              <div className="col-lg-6">
                <div className="accordion" id="accordionLeft">
                  <div className="accordion-item">
                    <h6 className="accordion-header" id="headingLeftOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseLeftOne"
                        aria-expanded="false"
                        aria-controls="collapseLeftOne"
                      >
                        How to send money online?
                      </button>
                    </h6>
                    <div
                      id="collapseLeftOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingLeftOne"
                      data-bs-parent="#accordionLeft"
                    >
                      <div className="accordion-body">
                        <p>Visit the 3 step process above.</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="accordion-item">
                    <h6 className="accordion-header" id="headingLeftTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseLeftTwo"
                        aria-expanded="false"
                        aria-controls="collapseLeftTwo"
                      >
                        How much are money transfer fees?
                      </button>
                    </h6>
                    <div
                      id="collapseLeftTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingLeftTwo"
                      data-bs-parent="#accordionLeft"
                    >
                      <div className="accordion-body">
                        <p>
                          Depends on transfer details
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="accordion-item">
                    <h6 className="accordion-header" id="headingLeftThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseLeftThree"
                        aria-expanded="false"
                        aria-controls="collapseLeftThree"
                      >
                        What is the fastest way to send money abroad?
                      </button>
                    </h6>
                    <div
                      id="collapseLeftThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingLeftThree"
                      data-bs-parent="#accordionLeft"
                    >
                      <div className="accordion-body">
                        <p>
                          By utilizing our services to make an instant transfer.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h6 className="accordion-header" id="headingLeftFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseLeftFour"
                        aria-expanded="false"
                        aria-controls="collapseLeftFour"
                      >
                        How to use Paylio?
                      </button>
                    </h6>
                    <div
                      id="collapseLeftFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingLeftFour"
                      data-bs-parent="#accordionLeft"
                    >
                      <div className="accordion-body">
                        <p>
                          Simply register and login to your dashboard and you
                          have access to a wealth of financial options.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h6 className="accordion-header" id="headingLeftFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseLeftFive"
                        aria-expanded="false"
                        aria-controls="collapseLeftFive"
                      >
                        How does Paylio protect your money?
                      </button>
                    </h6>
                    <div
                      id="collapseLeftFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingLeftFive"
                      data-bs-parent="#accordionLeft"
                    >
                      <div className="accordion-body">
                        <p>
                          We make use of state of the art, industry standard
                          security measures and encryption to process
                          transactions and secure funds.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="accordion" id="accordionRight">
                  <div className="accordion-item">
                    <h6 className="accordion-header" id="headingRightOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseRightOne"
                        aria-expanded="false"
                        aria-controls="collapseRightOne"
                      >
                        Is your platform safe?
                      </button>
                    </h6>
                    <div
                      id="collapseRightOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingRightOne"
                      data-bs-parent="#accordionRight"
                    >
                      <div className="accordion-body">
                        <p>
                          Our platorm is as safe as they come. You have
                          absolutely nothing to worry about.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h6 className="accordion-header" id="headingRightTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseRightTwo"
                        aria-expanded="false"
                        aria-controls="collapseRightTwo"
                      >
                        How much money can I send?
                      </button>
                    </h6>
                    <div
                      id="collapseRightTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingRightTwo"
                      data-bs-parent="#accordionRight"
                    >
                      <div className="accordion-body">
                        <p>
                          You can send as low as $10 and as high as $100,000.
                          You choose.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h6 className="accordion-header" id="headingRightThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseRightThree"
                        aria-expanded="false"
                        aria-controls="collapseRightThree"
                      >
                        Which currency can I send?
                      </button>
                    </h6>
                    <div
                      id="collapseRightThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingRightThree"
                      data-bs-parent="#accordionRight"
                    >
                      <div className="accordion-body">
                        <p>
                          You can send US dollars only for the time being but
                          we're working on adding more currencies.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h6 className="accordion-header" id="headingRightFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseRightFive"
                        aria-expanded="false"
                        aria-controls="collapseRightFive"
                      >
                        Can I send multiple payments?
                      </button>
                    </h6>
                    <div
                      id="collapseRightFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingRightFive"
                      data-bs-parent="#accordionRight"
                    >
                      <div className="accordion-body">
                        <p>No you can only make a single payment at a time.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
