import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="header-section">
      <div className="overlay">
        <div className="container">
          <div className="row d-flex header-area">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link className="navbar-brand" to="/">
                <img
                  src="/assets/images/logo.png"
                  className="logo"
                  alt="logo"
                />
              </Link>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar-content"
              >
                <i className="fas fa-bars" />
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbar-content"
              >
                <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                  <li className="nav-item dropdown main-navbar">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      Personal
                    </a>
                    <ul className="dropdown-menu main-menu shadow">
                      <li>
                        <Link className="nav-link" to="/payments">
                          Freelancer Payments
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/subscriptions">
                          Subscriptions
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/security">
                          Security
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown main-navbar">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      Business
                    </a>
                    <ul className="dropdown-menu main-menu shadow">
                      <li>
                        <Link className="nav-link" to="/payments">
                          Business Payments
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/business-account">
                          Business Account
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/corporate-card">
                          Corporate Card
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/expense-management">
                          Expense Management
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/budgeting">
                          Budgeting
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/integrations">
                          Integrations
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/invoice-management">
                          Invoice
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/security">
                          Security
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown main-navbar">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      Company
                    </a>
                    <ul className="dropdown-menu main-menu shadow">
                      <li>
                        <Link className="nav-link" to="/about">
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/blog">
                          Blog
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown main-navbar">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      Help
                    </a>
                    <ul className="dropdown-menu main-menu shadow">
                      <li>
                        <Link className="nav-link" to="/help-center">
                          Help Center
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div className="right-area header-action d-flex align-items-center max-un">
                  <a
                    href="https://dashboard.payliofinance.com/login"
                    className="login"
                  >
                    Login
                  </a>
                  <a
                    href="https://dashboard.payliofinance.com/register"
                    className="cmn-btn"
                  >
                    Sign Up
                    <i className="icon-d-right-arrow-2" />
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
